import React, {FC, useEffect} from "react";
import {Button, Divider, Grid, Stack, Typography} from "@mui/material";
import {Authenticated} from "../_shared/Authenticated";
import {KimaiSettings} from "./KimaiSettings";
import {useDispatch} from "react-redux";
import {validateKimaiSettingsAction} from "./importerSettingsSlice";
import {ActivityList} from "./ActivityList";
import {CalendarEntries} from "./CalendarEntries";

export const Importer: FC = () => {
    return (
        <Grid direction="row" container justifyContent='space-between'>
            <Grid item xs={12} sm={9}>
                <Stack spacing={2}>
                    <Typography variant="h5" component="h2">Timesheet Importer</Typography>
                    <Typography variant="h6">Instructions</Typography>
                    <ol>
                        <li>Log in using your MJL Account</li>
                        <li>Add your Kimai API key to this page</li>
                        <li>Select calendar events on the left side</li>
                        <li>Select timesheet activities on the right side</li>
                    </ol>
                </Stack>
            </Grid>
            <Grid container item xs={12} sm={3} alignItems='flex-end'>
                <KimaiSettings />
            </Grid>
            <Authenticated>
                <Grid item sx={{my: 4}} xs={12}><Divider/></Grid>
                <Grid item md={3} xs={12}>
                    <CalendarEntries />
                </Grid>
                <Grid container item md={1} justifyContent='center'><Divider orientation='vertical'/></Grid>
                <Grid item md={8}>
                    <ActivityList />
                </Grid>
            </Authenticated>
        </Grid>
    );
}