import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {useSelector} from "react-redux";
import {RootState} from "../store";

export type SnackbarMessage = {
    isVisible: boolean;
    message?: string,
    severity?: 'error' | 'warning' | 'info' | 'success',
}

export type GlobalState = {
    snackbar: SnackbarMessage
}

export const globalSlice = createSlice({
    name: "global",
    initialState: {
        snackbar: {
            isVisible: false
        }
    } as GlobalState,
    reducers: {
        showToastMessageAction: (state, action: PayloadAction<Omit<SnackbarMessage, 'isVisible'>>) => {
            state.snackbar = {
                ...action.payload,
                severity: action.payload.severity || 'info',
                isVisible: true
            }
        },
        hideToastMessageAction: (state) => {
            state.snackbar.isVisible = false;
        }
    }
});

export const {showToastMessageAction, hideToastMessageAction} = globalSlice.actions;
export const useGlobal = () => useSelector((state: RootState) => state.global);