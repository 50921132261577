import React, {FC} from "react";
import {hideToastMessageAction, useGlobal} from "./globalSlice";
import {useDispatch} from "react-redux";
import {Alert, Snackbar} from "@mui/material";

export const Toast: FC = () => {
    const {snackbar} = useGlobal();
    const dispatch = useDispatch();
    const handleClose = () => dispatch(hideToastMessageAction());
    return (
        <Snackbar autoHideDuration={5000} onClose={handleClose} open={snackbar.isVisible}>
            <Alert onClose={handleClose} severity={snackbar.severity}>
                {snackbar.message}
            </Alert>
        </Snackbar>
    );
}