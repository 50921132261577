import {all, put, select, takeLatest} from "redux-saga/effects";
import {
    failureGotGoogleCalendarEventsAction,
    failureLoginToGoogleAction,
    getGoogleCalendarEventsAction,
    loginToGoogleAction,
    setDateAction,
    successGotGoogleCalendarEventsAction,
    successLoginToGoogleAction
} from "./googleCalendarSlice";
import {showToastMessageAction} from "../../global/globalSlice";
import {getTimesheetEntriesAction} from "./importerSlice";
import {
    addDays,
    addMinutes,
    format,
    parse,
    getYear,
    getMonth,
    getDay,
    getDate,
    set
} from "date-fns";

function* setDate() {
    yield put(getTimesheetEntriesAction());
    yield put(getGoogleCalendarEventsAction());
}

export function* login() {
    try {
        yield new Promise((resolve) => gapi.load('client', resolve));
        yield gapi.client.init({
            clientId: '509348758560-376bb4rmd6olr1bvf118299t5f8kr2fk.apps.googleusercontent.com',
            scope: 'profile https://www.googleapis.com/auth/calendar.readonly',
        });
        yield new Promise((resolve, reject) => {
            gapi.auth.authorize({
                client_id: '509348758560-376bb4rmd6olr1bvf118299t5f8kr2fk.apps.googleusercontent.com',
                scope: 'profile https://www.googleapis.com/auth/calendar.readonly',

            }, (result) => result.access_token ? resolve(result) : reject(result));
        });
        yield put(successLoginToGoogleAction());
        yield put(getGoogleCalendarEventsAction());
    } catch (e) {
        yield put(failureLoginToGoogleAction());
        yield put(showToastMessageAction({message: 'Login failed', severity: 'error'}));
    }
}

function* getGoogleCalendarEvents() {
    try {
        const {date} = yield select((state) => state.googleCalendar);
        const offset = new Date().getTimezoneOffset();
        const timeMin = addMinutes(parse(date, 'yyyy-MM-dd', new Date()), offset);
        const timeMax = addDays(timeMin, 1);
        const eventResponse: gapi.client.Response<gapi.client.calendar.Events> = yield gapi.client.request({
            'path': 'https://www.googleapis.com/calendar/v3/calendars/primary/events',
            params: {
                timeMin: timeMin.toISOString(),
                timeMax: timeMax.toISOString()
            }
        });
        let events = eventResponse.result.items?.filter(e => e.status === 'confirmed') ?? [];
        // Remove duplicates, because that's annoying
        events = [...new Map(events.map(e => [`${e.summary} ${format(new Date(e.start?.dateTime ?? ''), 'hh:mm')}`, e])).values()]
        events = events.map(e => {
            // For repeating events, we need to reset the date of the repeating event to today
            if (e.start && new Date(e.start.dateTime ?? '') < timeMin && e.end && new Date(e.end.dateTime ?? '') < timeMin) {
                e.start.dateTime = set(new Date(e.start?.dateTime ?? ''), {
                    year: getYear(timeMin),
                    month: getMonth(timeMin),
                    date: getDate(timeMin)
                }).toISOString();
                e.end.dateTime = set(new Date(e.end?.dateTime ?? ''), {
                    year: getYear(timeMin),
                    month: getMonth(timeMin),
                    date: getDate(timeMin)
                }).toISOString();
            }
            return e;
        })
        // const events = eventResponse.result.items?.filter((event) => timeMin <= new Date(event.start?.dateTime ?? '') && new Date(event.start?.dateTime ?? '') < timeMax) ?? [];
        yield put(successGotGoogleCalendarEventsAction(events));
    } catch (e) {
        console.log(e);
        yield put(failureGotGoogleCalendarEventsAction());
        yield put(showToastMessageAction({message: 'Could not get calendar', severity: 'error'}));
    }
}

export function* googleCalendarSaga() {
    yield all([
        takeLatest(loginToGoogleAction.type, login),
        takeLatest(getGoogleCalendarEventsAction.type, getGoogleCalendarEvents),
        takeLatest(setDateAction.type, setDate),
    ])
}