import React, {FC, MouseEvent, useEffect, useState} from "react";
import {
    Button,
    Checkbox, FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    LinearProgress,
    ListItemText,
    MenuItem,
    Select,
    Stack,
    Switch
} from "@mui/material";
import {ImporterState, importTimesheetsAction, useImporter} from "./importerSlice";
import {useDispatch} from "react-redux";

export const ActivityList: FC = () => {
    const dispatch = useDispatch();
    const importer: ImporterState = useImporter();
    const mjlProjects = [10, 11, 18];
    const [selectedProjects, setSelectedProjects] = React.useState<number[]>([]);
    const [availableProjects, setAvailableProjects] = useState(new Map<number, string>());
    useEffect(() => {
        setAvailableProjects(new Map<number, string>(importer.activities.value.map(a => ([
                a.project ?? -1,
                a.parentTitle ?? ''
            ]
        ))));
    }, [importer.activities.value]);

    const createImportTimesheetHandler = (projectId: number, activityId: number) => (e: MouseEvent<HTMLButtonElement>) => {
        dispatch(importTimesheetsAction({projectId, activityId}));
    }

    const [includeMjl, setIncludeMjl] = React.useState(false);

    return (
        <Stack spacing={2}>
            <FormGroup>
                <Stack direction='row' spacing={2} justifyContent='flex-end'>
                    <FormControl fullWidth>
                        <InputLabel id='filterProjects'>Filter Projects</InputLabel>
                        <Select
                            labelId={'filterProjects'}
                            label='Filter Projects'
                            multiple
                            renderValue={(selected) => selected.map(x => availableProjects.get(x)).join(', ')}
                            value={selectedProjects}
                            onChange={x => setSelectedProjects(x.target.value as number[])}>
                            {
                                [...availableProjects.entries()]
                                    .filter(([id]) => includeMjl || !mjlProjects.includes(id))
                                    .map(([id, title]) => (
                                        <MenuItem key={id} value={id}>
                                            <Checkbox checked={selectedProjects.includes(id)}/>
                                            <ListItemText primary={title}/>
                                        </MenuItem>
                                    ))
                            }
                        </Select>
                    </FormControl>
                    <FormControlLabel
                        control={<Switch value={includeMjl} onChange={x => setIncludeMjl(x.target.checked)}/>}
                        label='Include MJL'/>

                </Stack>
            </FormGroup>
            {
                importer.activities.loading && !importer.activities.loaded
                    ? <LinearProgress/>
                    :
                    <>
                        {importer.activities.value
                            .filter(x => x.id && x.project)
                            .filter(x => includeMjl || !mjlProjects.some(y => y === x.project))
                            .filter(x => selectedProjects.length === 0 || selectedProjects.includes(x.project ?? -1))
                            .map(x => (
                                <Button key={x.id} fullWidth variant={'outlined'}
                                        onClick={createImportTimesheetHandler(x.project!, x.id!)}>
                                    {x.parentTitle} - {x.name}
                                </Button>
                            ))}
                    </>
            }
        </Stack>
    );
}