import {createSlice} from "@reduxjs/toolkit";
import {useSelector} from "react-redux";
import {RootState} from "../store";

export const userSlice = createSlice({
    name: "user",
    initialState: {},
    reducers: {}
});

// export const [] = userSlice.actions;
export const useUser = () => useSelector((state: RootState) => state.user);