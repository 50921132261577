export class KimaiTokenSingleton {
    private static instance: KimaiTokenSingleton;
    private Username?: string;
    private Password?: string;
    private ImpersonatingUser?: string;

    private constructor() {
    }

    private static getInstance(): KimaiTokenSingleton {
        if (!KimaiTokenSingleton.instance) {
            KimaiTokenSingleton.instance = new KimaiTokenSingleton();
        }

        return KimaiTokenSingleton.instance;
    }

    public static getUsername() {
        const instance = KimaiTokenSingleton.getInstance();
        return instance.Username;
    }

    public static setUsername(username: string) {
        const instance = KimaiTokenSingleton.getInstance();
        instance.Username = username;
    }

    public static getPassword() {
        const instance = KimaiTokenSingleton.getInstance();
        return instance.Password;
    }

    public static setPassword(password: string) {
        const instance = KimaiTokenSingleton.getInstance();
        instance.Password = password;
    }

    public static clearLoginInformation() {
        const instance = KimaiTokenSingleton.getInstance();
        instance.Username = undefined;
        instance.Password = undefined;
    }
}
