import {createTheme} from '@mui/material';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#92d5d1'
        },
        secondary: {
            main: '#fcc57c',
        },
    },
    components: {
        MuiButton: {
            defaultProps: {
                disableElevation: true
            },
            styleOverrides: {
                root: {
                    fontWeight: 'bold'
                },
            }
        }
    }
});