import {createReduxHistoryContext} from "redux-first-history";
import {createBrowserHistory} from "history";
import createSagaMiddleware from "redux-saga";
import {rootSaga} from "./rootSaga";
import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {persistStore, persistReducer} from 'redux-persist';
import {userSlice} from "./shared/userSlice";
import {importerSlice} from "./areas/importer/importerSlice";
import {importerSettingsSlice} from "./areas/importer/importerSettingsSlice";
import {globalSlice} from "./global/globalSlice";
import {PersistConfig} from "redux-persist/es/types";
import storage from 'redux-persist/lib/storage';
import {FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE} from "redux-persist/es/constants";
import {googleCalendarSlice} from "./areas/importer/googleCalendarSlice";

const sagaMiddleware = createSagaMiddleware();

const persistConfig: PersistConfig<any> = {
    key: 'root',
    storage: storage,
    whitelist: ['importerSettings']
}

const {createReduxHistory, routerMiddleware, routerReducer} = createReduxHistoryContext({
    history: createBrowserHistory(),
});

const rootReducer = combineReducers({
    global: globalSlice.reducer,
    user: userSlice.reducer,
    googleCalendar: googleCalendarSlice.reducer,
    importer: importerSlice.reducer,
    importerSettings: importerSettingsSlice.reducer,
    router: routerReducer
});

export const store = configureStore({
    reducer: persistReducer(persistConfig, rootReducer) as typeof rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
    }).concat(sagaMiddleware, routerMiddleware),
});

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
export const history = createReduxHistory(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;