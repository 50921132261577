import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {initLoadableCollection, LoadableCollection} from "../_shared/apiData";
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import {format} from "date-fns";

export type GoogleCalendarState = {
    isLoggedIn: boolean;
    date: string;
    calendarEvents: LoadableCollection<gapi.client.calendar.Event>;
    selectedEvents: string[];
}

const initialState: GoogleCalendarState = {
    isLoggedIn: false,
    date: format(new Date(), 'yyyy-MM-dd'),
    calendarEvents: initLoadableCollection(),
    selectedEvents: []
}

export const googleCalendarSlice = createSlice({
    name: 'googleCalendar',
    initialState: initialState,
    reducers: {
        loginToGoogleAction: (state) => {
        },
        successLoginToGoogleAction: (state) => {
            state.isLoggedIn = true;
        },
        failureLoginToGoogleAction: (state) => {
            state.isLoggedIn = false;
        },
        getGoogleCalendarEventsAction: (state) => {
            state.calendarEvents.loading = true;
        },
        successGotGoogleCalendarEventsAction: (state, action: PayloadAction<gapi.client.calendar.Event[]>) => {
            state.calendarEvents.loading = false;
            state.calendarEvents.loaded = false;
            state.calendarEvents.value = action.payload;
        },
        failureGotGoogleCalendarEventsAction: (state) => {
            state.calendarEvents.loading = false;
        },
        setDateAction: (state, action: PayloadAction<string>) => {
            state.date = action.payload;
        },
        toggleEventAction: (state, action: PayloadAction<string>) => {
            if (!state.selectedEvents.includes(action.payload)) {
                state.selectedEvents.push(action.payload);
            } else {
                state.selectedEvents.splice(state.selectedEvents.indexOf(action.payload), 1);
            }
        },
        clearSelectedEventsAction: (state) => {
            state.selectedEvents = [];
        }
    }
});

export const {
    loginToGoogleAction,
    successLoginToGoogleAction,
    failureLoginToGoogleAction,
    getGoogleCalendarEventsAction,
    successGotGoogleCalendarEventsAction,
    failureGotGoogleCalendarEventsAction,
    setDateAction,
    toggleEventAction,
    clearSelectedEventsAction
} = googleCalendarSlice.actions;

export const useGoogle  = () => useSelector((state: RootState) => state.googleCalendar);