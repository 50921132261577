import * as React from 'react';
import {FC} from 'react';
import {Route, Routes} from 'react-router-dom';
import {Layout} from "./global/Layout";
import {Importer} from "./areas/importer/Importer";
import {Toast} from "./global/Toast";

export const App: FC = () => {
    return (
        <Layout>
            <Toast/>
            <Routes>
                <Route path='/' element={<Importer/>}/>
            </Routes>
        </Layout>
    )
};
