import React, {FC, FormEvent, useEffect, useState} from "react";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {Lock, LockOpen} from "@mui/icons-material";
import {
    setKimaiAuthAction,
    useImporterSettings
} from "./importerSettingsSlice";
import {useDispatch} from "react-redux";
import {getAvailableActivitiesAction} from "./importerSlice";

export const KimaiSettings: FC = () => {
    const importerSettings = useImporterSettings();
    const dispatch = useDispatch();
    const [kimaiModal, setKimaiModal] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    useEffect(() => {
        if (importerSettings.isValid) {
            dispatch(setKimaiAuthAction({username: importerSettings.apiUser ?? '', password: importerSettings.apiPassword ?? ''}));
        }
    }, [])
    const handleSave = (e: FormEvent) => {
        e.preventDefault();
        dispatch(setKimaiAuthAction({username, password}));
        setKimaiModal(false)
    }
    return (
        <>
            <Dialog open={kimaiModal} onClose={() => setKimaiModal(false)}>
                <form onSubmit={handleSave}>
                    <DialogTitle>Set your Kimai API Credentials</DialogTitle>
                    <DialogContent>
                        <Stack sx={{paddingTop: 2}} spacing={2}>
                            <TextField label='Username'
                                       autoFocus
                                       value={username}
                                       onChange={(e) => setUsername(e.target.value)}/>
                            <TextField label='Password'
                                       type='password'
                                       value={password}
                                       onChange={(e) => setPassword(e.target.value)}/>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button type='submit'>Save</Button>
                    </DialogActions>
                </form>
            </Dialog>
            <Button fullWidth
                    variant={importerSettings.isValid ? 'contained' : 'outlined'}
                    onClick={() => setKimaiModal(true)}>
                <Stack direction='row' spacing={2}>
                    {
                        importerSettings.validating
                            ? <CircularProgress sx={{color: importerSettings.isValid ? 'black' : undefined}} size={24}/>
                            : importerSettings.isValid ? <Lock/> : <LockOpen/>
                    }
                    <Typography>Kimai Login Info</Typography>
                </Stack>
            </Button>
        </>
    );
}