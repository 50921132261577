import {FC, PropsWithChildren, useRef, useState} from "react";
import {AppBar, Avatar, Box, Button, Container, Link, Menu, MenuItem, Stack, Toolbar, Typography} from "@mui/material";
import banana from './banana.svg'

export const Layout: FC<PropsWithChildren> = ({children}) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const avatarRef = useRef(null);
    return (
        <Box>
            <AppBar>
                <Toolbar>
                    <Stack sx={{width: '100%'}} direction={'row'} spacing={2} alignItems='center'>
                        <Link href='/'><img src={banana} alt="Monkeyjump Labs" style={{height: '40px'}}/></Link>
                        <Typography variant="h5" component="h1">MJL Tools</Typography>
                        <Box sx={{flexGrow: 1}}/>
                    </Stack>
                </Toolbar>
            </AppBar>
            <Box>
                <Container sx={{marginTop: 12}}>
                    {children}
                </Container>
            </Box>
        </Box>
    );
}