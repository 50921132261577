export type Loadable<T> = {
    loading: boolean;
    loaded: boolean;
    value?: T;
}

export function initLoadable<T>(value?: T): Loadable<T> {
    return {
        loading: false,
        loaded: false,
        value: value,
    }
}

export type LoadableCollection<T> = {
    loading: boolean;
    loaded: boolean;
    value: T[];
}

export function initLoadableCollection<T>(value?: T[]): LoadableCollection<T> {
    return {
        loading: false,
        loaded: false,
        value: value ?? [],
    }
}