import React, {FC} from "react";
import {Button, LinearProgress, Stack, TextField, Typography} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import {useDispatch} from "react-redux";
import {format, parse} from "date-fns";
import {Google} from "@mui/icons-material";
import {loginToGoogleAction, setDateAction, toggleEventAction, useGoogle} from "./googleCalendarSlice";
import {useImporter} from "./importerSlice";

const GoogleLoginButton: FC = () => {
    const dispatch = useDispatch();
    const {isLoggedIn} = useGoogle();
    const handleGoogleLogin = async () => {
        dispatch(loginToGoogleAction());
    }
    return (
        <Button variant={isLoggedIn ? 'contained' : 'outlined'} color='primary' onClick={handleGoogleLogin}>
            <Stack direction='row' spacing={2}>
                <Google/>
                <Typography>Log In</Typography>
            </Stack>
        </Button>
    );
}

export const CalendarEntries: FC = () => {
    const dispatch = useDispatch();
    const {isLoggedIn, date, calendarEvents, selectedEvents} = useGoogle();
    const {timesheets} = useImporter();
    const handleDateChange = (date?: Date | null) => {
        if (date) dispatch(setDateAction(format(date, 'yyyy-MM-dd')));
    };
    const isSelected = (eventId?: string) => eventId && selectedEvents.some(e => e === eventId);
    const doesTimesheetOverlap = (startTime: string, endTime: string) => {
        const startDate = new Date(startTime);
        const endDate = new Date(endTime);
        // return timesheets.value.some(ts => ts.begin < endDate);
        return timesheets.value.some(ts => new Date(ts.begin) < endDate && (!ts.end || new Date(ts.end) > startDate));
    }
    return (
        <Stack spacing={2}>
            {!isLoggedIn
                ? <GoogleLoginButton/>

                :
                <>
                    <DatePicker label='Date to Import'
                                renderInput={(params) => <TextField fullWidth {...params}/>}
                                value={parse(date, 'yyyy-MM-dd', new Date())}
                                onChange={handleDateChange}/>

                    {
                        calendarEvents.loading
                            ? <LinearProgress/>
                            : calendarEvents.value
                                .filter(e => e.start?.dateTime && e.end?.dateTime && e.id)
                                .sort((a, b) => format(new Date(a.start!.dateTime!), 'HH:mm').localeCompare(format(new Date(b.start!.dateTime!), 'HH:mm')))
                                .map(e => (
                                    <Button key={e.id}
                                            fullWidth
                                            disabled={doesTimesheetOverlap(e.start!.dateTime!, e.end!.dateTime!)}
                                            variant={isSelected(e.id) ? 'contained' : 'outlined'}
                                            onClick={() => dispatch(toggleEventAction(e.id!))}>
                                        <Stack spacing={1}>
                                            <Typography>
                                                {format(new Date(e.start!.dateTime!), 'hh:mm')} - {format(new Date(e.end!.dateTime!), 'hh:mm')}
                                            </Typography>
                                            <Typography>{e.summary}</Typography>
                                        </Stack>
                                    </Button>
                                ))
                    }
                </>
            }
        </Stack>
    );
};