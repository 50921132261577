import * as React from 'react';
import {Provider} from 'react-redux';
import {App} from './App';
import registerServiceWorker from './registerServiceWorker';
import {HistoryRouter} from "redux-first-history/rr6";
import {history, persistor, store} from "./store";
import {CircularProgress, ThemeProvider} from "@mui/material";
import {theme} from "./appTheme";
import {createRoot} from "react-dom/client";
import {PersistGate} from "redux-persist/integration/react";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";

// Create browser history to use in the Redux store
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;

const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <PersistGate loading={<CircularProgress/>} persistor={persistor}>
                    <HistoryRouter history={history}>
                        <App/>
                    </HistoryRouter>
                </PersistGate>
            </LocalizationProvider>
        </ThemeProvider>
    </Provider>
);

registerServiceWorker();
