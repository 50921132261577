import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import {KimaiTokenSingleton} from "../../services/kimaiTokenSingleton";


export type ImporterSettingsState = {
    apiUser?: string,
    apiPassword?: string,
    isValid: boolean,
    validating: boolean
}

export const importerSettingsSlice = createSlice({
    name: 'importerSettings',
    initialState: {
        apiUser: undefined,
        apiPassword: undefined,
        isValid: false
    } as ImporterSettingsState,
    reducers: {
        setKimaiAuthAction: (state, action: PayloadAction<{ username: string, password: string }>) => {
            state.apiUser = action.payload.username;
            KimaiTokenSingleton.setUsername(action.payload.username);
            state.apiPassword = action.payload.password;
            KimaiTokenSingleton.setPassword(action.payload.password);
        },
        validateKimaiSettingsAction: (state) => {
            state.validating = true;
        },
        setIsValidAction: (state, action: PayloadAction<boolean>) => {
            state.validating = false;
            state.isValid = action.payload;
        }
    }
});

export const {setKimaiAuthAction, setIsValidAction, validateKimaiSettingsAction} = importerSettingsSlice.actions;
export const useImporterSettings = () => useSelector((state: RootState) => state.importerSettings);