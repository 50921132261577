import {all, put, select, takeLatest} from "redux-saga/effects";
import {setIsValidAction, setKimaiAuthAction, validateKimaiSettingsAction} from "./importerSettingsSlice";
import {RootState} from "../../store";
import {kimaiCall, kimaiClient} from "../../services/kimaiClient";
import {
    ActivityCollection, ITimesheetEditForm,
    TimesheetCollection,
    TimesheetEditForm,
    UserEntity
} from "../../services/generated/KimaiClientGenerated";
import {
    failureGetAvailableActivitiesAction,
    failureGetMeAction,
    failureGetTimesheetEntriesAction,
    getAvailableActivitiesAction,
    getMeAction,
    getTimesheetEntriesAction,
    ImporterState, importTimesheetsAction,
    successGetAvailableActivitiesAction,
    successGetMeAction,
    successGetTimesheetEntriesAction, successImportTimesheetsAction
} from "./importerSlice";
import {showToastMessageAction} from "../../global/globalSlice";
import {addDays, addMinutes, format, parse} from "date-fns";
import {PayloadAction} from "@reduxjs/toolkit";
import {clearSelectedEventsAction, GoogleCalendarState} from "./googleCalendarSlice";

function* getMe() {
    try {

        const me: UserEntity = yield kimaiCall(kimaiClient.me);
        yield put(successGetMeAction(me.toJSON()));
    } catch {
        yield put(failureGetMeAction());
    }

}

function* getTimesheetEntries() {
    const {apiUser, apiPassword} = yield select((state: RootState) => state.importerSettings);
    const {me}: ImporterState = yield select((state: RootState) => state.importer);
    const {date} = yield select((state: RootState) => state.googleCalendar);
    const offset = new Date().getTimezoneOffset();
    const startDate = addMinutes(parse(date, 'yyyy-MM-dd', new Date()), offset);
    const endDate = addDays(startDate, 1);
    console.log(date, startDate, endDate);
    try {
        const results: TimesheetCollection[] = yield kimaiCall(kimaiClient.timesheetsAll,
            me.value?.id?.toString(), null, null, null, null, null, null, null,
            null, null, null, null,
            format(startDate, "yyyy-MM-dd'T'hh:mm:ss"),
            format(endDate, "yyyy-MM-dd'T'hh:mm:ss"),
            null, null, null, null, null, null);
        yield put(successGetTimesheetEntriesAction(results.map(x => x.toJSON())));
    } catch (e) {
        console.log(e);
        yield put(failureGetTimesheetEntriesAction());
    }
}

function* getAvailableActivities() {
    const {apiUser, apiPassword} = yield select((state: RootState) => state.importerSettings);
    try {
        const results: ActivityCollection[] = yield kimaiCall(kimaiClient.activitiesAll, null, null, null, null, null, null, null, null);
        yield put(successGetAvailableActivitiesAction(results.map(x => x.toJSON())));
    } catch (e) {
        yield put(failureGetAvailableActivitiesAction());
    }
}

function* tryValidate() {
    const {apiUser, apiPassword} = yield select((state: RootState) => state.importerSettings);
    if (apiUser && apiPassword) {
        try {
            yield put(validateKimaiSettingsAction());
            yield kimaiCall(kimaiClient.ping);
            yield put(showToastMessageAction({message: 'Successfully validated Kimai settings', severity: 'success'}));
            yield put(setIsValidAction(true));
            yield put(getMeAction());
            yield put(getAvailableActivitiesAction());
            yield put(getTimesheetEntriesAction());
        } catch {
            yield put(setIsValidAction(false));
        }
    } else {
        yield put(setIsValidAction(false));
    }
}

function* importTimesheets(action: PayloadAction<{ projectId: number, activityId: number }>) {
    try {
        const {me}: ImporterState = yield select((state: RootState) => state.importer);
        if (!me?.value) throw 'No user found';
        const {calendarEvents, selectedEvents}: GoogleCalendarState = yield select((r: RootState) => r.googleCalendar);
        const calendarEventsMap = new Map(calendarEvents.value.map(x => [x.id, x]));
        for (const selectedEvent of selectedEvents) {
            const event = calendarEventsMap.get(selectedEvent);
            if (!event) return;

            const request: ITimesheetEditForm = {
                activity: action.payload.activityId,
                project: action.payload.projectId,
                begin: new Date(event.start?.dateTime ?? ''),
                end: new Date(event.end?.dateTime ?? ''),
                user: me.value!.id,
                description: event.summary
            };
            yield kimaiCall(kimaiClient.timesheetsPOST, new TimesheetEditForm(request), null);
        }
        yield put(successImportTimesheetsAction());
        yield put(getTimesheetEntriesAction());
        yield put(clearSelectedEventsAction());
    } catch {
        yield put(showToastMessageAction({message: 'Failed to import timesheets', severity: 'error'}));
        yield put(failureGetTimesheetEntriesAction());
    }
}

export function* importerSaga() {
    yield all([
        takeLatest(setKimaiAuthAction.type, tryValidate),
        takeLatest(getAvailableActivitiesAction.type, getAvailableActivities),
        takeLatest(getTimesheetEntriesAction.type, getTimesheetEntries),
        takeLatest(getMeAction.type, getMe),
        takeLatest(importTimesheetsAction.type, importTimesheets)
    ]);
}