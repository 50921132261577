import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IActivityCollection, ITimesheetCollection, IUserEntity} from "../../services/generated/KimaiClientGenerated";
import {initLoadable, initLoadableCollection, Loadable, LoadableCollection} from "../_shared/apiData";
import {RootState} from "../../store";
import {useSelector} from "react-redux";

export type ImporterState = {
    me: Loadable<IUserEntity>,
    activities: LoadableCollection<IActivityCollection>,
    timesheets: LoadableCollection<ITimesheetCollection>
}

const initialState: ImporterState = {
    me: initLoadable(),
    activities: initLoadableCollection(),
    timesheets: initLoadableCollection()
}

export const importerSlice = createSlice({
    name: 'importer',
    initialState: initialState,
    reducers: {
        getAvailableActivitiesAction: (state) => {
            state.activities.loading = true;
        },
        successGetAvailableActivitiesAction: (state, action: PayloadAction<IActivityCollection[]>) => {
            state.activities.loading = false;
            state.activities.loaded = true;
            state.activities.value = action.payload.sort((a, b) => a.parentTitle?.localeCompare(b.parentTitle ?? '') ?? 0);
        },
        failureGetAvailableActivitiesAction: (state) => {
            state.activities.loading = false;
            state.activities.loaded = false;
        },
        getTimesheetEntriesAction: (state) => {
            state.timesheets.loading = true;
        },
        successGetTimesheetEntriesAction: (state, action: PayloadAction<ITimesheetCollection[]>) => {
            state.timesheets.loading = false;
            state.timesheets.loaded = true;
            state.timesheets.value = action.payload;
        },
        failureGetTimesheetEntriesAction: (state) => {
            state.timesheets.loading = false;
            state.timesheets.loaded = false;
        },
        getMeAction: (state) => {
            state.me.loading = true;
        },
        successGetMeAction: (state, action: PayloadAction<IUserEntity>) => {
            state.me.loading = false;
            state.me.loaded = true;
            state.me.value = action.payload;
        },
        failureGetMeAction: (state) => {
            state.me.loading = false;
            state.me.loaded = false;
        },
        importTimesheetsAction(state, action: PayloadAction<{projectId: number, activityId: number}>) {
        },
        successImportTimesheetsAction(state) {
        },
        failureImportTimesheetsAction(state) {
        }
    }
});

export const {
    getAvailableActivitiesAction,
    successGetAvailableActivitiesAction,
    failureGetAvailableActivitiesAction,
    getTimesheetEntriesAction,
    successGetTimesheetEntriesAction,
    failureGetTimesheetEntriesAction,
    getMeAction,
    successGetMeAction,
    failureGetMeAction,
    importTimesheetsAction,
    successImportTimesheetsAction,
    failureImportTimesheetsAction
} = importerSlice.actions;
export const useImporter: (() => ImporterState) = () => useSelector((state: RootState) => state.importer);
