import {call, put} from 'redux-saga/effects';
import * as ApiClientGenerated from './generated/KimaiClientGenerated';
import {KimaiTokenSingleton} from "./kimaiTokenSingleton";

export const kimaiClient = new ApiClientGenerated.ApiClient(process.env.REACT_APP_API_URL, {
    fetch: async (url, init?: RequestInit) => {
        const username = KimaiTokenSingleton.getUsername();
        const password = KimaiTokenSingleton.getPassword();
        let headers: HeadersInit = {
            ...(init?.headers || {}),
            'X-AUTH-USER': username ?? '',
            'X-AUTH-TOKEN': password ?? ''
        };
        const response = await fetch(url, {
            ...init,
            headers,
        });
        if (response.status === 401 && !window.location.pathname.match(/^\/login/)) {
            // may need to http encoded location.pathname
            history.pushState({}, '', `/login?redirectUrl=${location.pathname}`);
        }
        return response;
    },
});

/**
 * Helper to call our API from redux-saga and handle errors in one place as necessary
 */
export function* kimaiCall<Fn extends (this: ApiClientGenerated.ApiClient, ...args: any[]) => TResponse, TResponse>(
    fn: Fn,
    ...args: Parameters<Fn>
): Generator<any> {
    try {
        return yield call<ApiClientGenerated.ApiClient, Fn>([kimaiClient, fn], ...args);
    } catch (e: any) {
        if (e.status === 403) {
            // yield put(
            //     showToastMessageAction({
            //         message: 'Permission denied',
            //         severity: 'warning',
            //     }),
            // );
            throw e;
        } else {
            throw e;
        }
    }
}
